/**
 * LocationLocaleDialog
 * 
 * @Author: Focci
 * @Date: 2024-01-10 10:49:59
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-10 10:49:59
 */

import PropTypes from 'prop-types'
import useDialog from '@hook/useDialog'
import useLocaleSwitch from '@hook/useLocaleSwitch'
import { useLocale, useTranslations } from 'next-intl'
import { filter } from 'lodash-es'
import { langOptions, defaultLocale } from '@i18n/config'
import { CircularProgress, ButtonBase } from '@comp/material'
import dynamic from 'next/dynamic'
import { useCallback, useMemo } from 'react'

const LanguageSetting = dynamic(() => import('@comp/page/common/lang/LanguageSetting'), {
  ssr: false,
  loading: () => (
    <div className="min-h-[24rem] flex items-center justify-center">
      <CircularProgress />
    </div>
  )
})

function getLabel(locale) {
  return filter(langOptions, (item) => item.value === locale)[0]?.label
}

function ResposiveTrigger({ label, flag, ...props }) {
  const tp = useTranslations('profile')
  const { getCountry } = useLocaleSwitch()
  const { FlagIcon, countryName } = getCountry(flag)

  return (
    <ButtonBase className="w-full" {...props}>
      <div className="flex justify-between items-center p-4 block w-full">
        <span className="text-f.8 font-medium">
          {tp('selectLanguage')}
        </span>
        <div className="flex items-center gap-x-1">
          <FlagIcon width="1.4rem" height="1.4rem" />
          <span className="text-f.8 text-meta">
            {`${countryName} / ${label}`}
          </span>
        </div>
      </div>
    </ButtonBase>
  )
}

export default function LocationLocaleDialog({ 
  flag,
  Trigger = ResposiveTrigger,
  onClose = () => {}
}) {
  const { openDialog, closeDialog, Dialog } = useDialog()
  const tp = useTranslations('profile')
  const locale = useLocale()
  const label = useMemo(() => (getLabel(locale) ?? getLabel(defaultLocale)), [locale])

  const handleClose = useCallback(() => {
    closeDialog()
    onClose()
  }, [closeDialog, onClose])

  return (
    <>
      <Trigger 
        onClick={openDialog}
        label={label}
        flag={flag}
      />
      <Dialog title={tp('language')}>
        <LanguageSetting onChange={handleClose} />
      </Dialog>
    </>
  )
}

LocationLocaleDialog.propTypes = {
  flag: PropTypes.string,
  Trigger: PropTypes.node,
}
