'use client'

import CustomDialog from '@comp/dialog/CustomDialog'
import useLogin from '@hook/useLogin'
import useLazyComponent from '@hook/useLazyComponent'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, CircularProgress } from '@comp/material'
import { useUnmount } from 'react-use'
import { useEffect } from 'react'

export default function Login() {
  const { open, closeDialog } = useLogin()
  const { Wedget: LoginBody, getWedget, ready } = useLazyComponent()

  useUnmount(() => { closeDialog() })
  useEffect(() => {
    open && getWedget(() => import('./LoginBody'))
  }, [open, getWedget])

  return open && (
    <CustomDialog
      fullScreen
      open={open}
    >
      <div className="relative h-full pt-pix-40">
        <IconButton className="absolute right-0 top-0" onClick={closeDialog}>
          <CloseIcon className="text-f1.2 text-primary" />
        </IconButton>
        {!ready && (
          <div 
            className="
              w-pix-480 py-40 max-w-full mx-auto bg-white relative 
              flex items-center justify-center
            " 
          >
            <CircularProgress />
          </div>
        )}
        <LoginBody />
      </div>
    </CustomDialog>
  )
}
