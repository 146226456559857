/**
 * LocationLocalePanel
 * 
 * @Author: Focci
 * @Date: 2024-01-10 11:00:11
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-10 11:00:11
 */

'use client'

// import PropTypes from 'prop-types'
import SVGLocale from '@img/locale.svg'
import useLazyComponent from '@hook/useLazyComponent'
import HoverDropdown from '@comp/HoverDropdown'
import { useCallback } from 'react'
import { CircularProgress } from '@comp/material'
import useLocaleSwitch from '@hook/useLocaleSwitch'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import clsx from 'clsx'

function LocationTrigger({
  className = 'text-white border rounded-full pl-2 pr-1',
  showIcon = true
}) {
  const { countryName } = useLocaleSwitch()

  return (
    <div 
      className={clsx([
        'flex items-center gap-x-0.5 h-6',
        className
      ])}
    >
      {showIcon && <SVGLocale width=".5rem" />}
      <span className="text-f.6 whitespace-nowrap">{countryName}</span>
      <KeyboardArrowDown sx={{ fontSize: '.7rem' }} />
    </div>
  )
}

export default function LocaleSwitcher({
  className = 'left-0',
  triggerProps
}) {
  const { Wedget: ProfilePanel, getWedget, ready } = useLazyComponent()
  const handleEnter = useCallback(() => {
    getWedget(() => import('./LocaleSwitcherBody'))
  }, [getWedget])

  return (
    <HoverDropdown
      trigger={<LocationTrigger {...triggerProps} />}
      onEnter={handleEnter}
      className={className}
    >
      <div className="bg-white shadow-large rounded-md">
        {!ready && (
          <div className="w-72 py-4 text-center">
            <CircularProgress />
          </div>
        )}
        <ProfilePanel />
      </div>
    </HoverDropdown>
  )
}

// LocaleSwitcher.propTypes = {
//   flag: PropTypes.string,
// }
