/**
 * 带顶部进度条的路由跳转
 * 
 * @Author: Focci
 * @Date: 2023-04-28 09:00:34
 * @Last Modified by: Focci
 * @Last Modified time: 2023-04-28 09:00:34
 */

import NProgress from 'nprogress'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'

function useProgressRouter() {
  const router = useRouter()

  const routerPush = useCallback((...args) => {
    NProgress.start()
    router.push(...args)
  }, [router])

  const urlPush = useCallback((...args) => {
    router.push(...args)
  }, [router])

  const routerReplace = useCallback((...args) => {
    NProgress.start()
    router.replace(...args)
  }, [router])

  const urlReplace = useCallback((...args) => {
    router.replace(...args)
  }, [router])

  const routerBack = useCallback(() => {
    NProgress.start()
    router.back()
  }, [router])

  const routerForward = useCallback(() => {
    NProgress.start()
    router.forward()
  }, [router])

  const routerRefresh = useCallback((...args) => {
    router.refresh(...args)
  }, [router])

  return {
    routerPush,
    urlPush,
    urlReplace,
    routerReplace,
    routerRefresh,
    routerBack,
    routerForward,
  }
}

export default useProgressRouter
