/**
 * 搜索历史记录
 * 
 * @Author: Focci
 * @Date: 2023-08-05 13:52:55
 * @Last Modified by: Focci
 * @Last Modified time: 2023-08-05 13:52:55
 */

import useFetch from '@hook/useFetch'
import { getListingModeParam } from '@lib/listing-mode'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { keys, isObject, filter, isArray, map, pick, omit, isString } from 'lodash-es'
import { filterConfig } from '@comp/page/common/filter/combine/filter-modal/meta'
import { typeOfId } from '@lib/config'
import { useLocale, useTranslations } from 'next-intl'
import { fetchPost, fetchPut } from '@lib/fetch/client'
import { args, urls } from '@lib/parse'
import { clean, isWindow } from '@lib/utils'
import { propertyDetail } from '@lib/route/house'
import useTracking from './useTracking'

function pushRecordApi(fetchParam, searchType) {
  let params
  try{ params = JSON.stringify(fetchParam) } catch(err) { /**/ }

  return fetchPost('user-search-record', { params, searchType })
}

/**
 * 获取搜索类型名称
 * 
 * ts = useTranslations('search')
 * ta = useTranslations('agent')
 */
export function getSearchTypeName(data, ts, ta) {
  const res = []
  const labels = {
    listing: ts('listings'),
    agent: ts('agent'),
    school: ts('schools'),
    street: ts('streets'),
    suburb: ta('suburb'),
    district: ta('district'),
    region: ts('district'),
  }

  if(isObject(data)) {
    keys(data).map((k) => labels[k] && res.push(labels[k]))
  }
  
  return res.join(',')
}

/**
 * 初始化搜索记录数据
 */
export function getInitRecordOption(item, locale) {
  const type = item.searchType
  const d = clean(omit(item.paramMeta, ['typeId']))
  const { fullname } = filterConfig[type] || {}

  // 房屋类型
  if(!d.list && isArray(d.ptnCategory)) {
    const propertyKeys = map(d.ptnCategory, 'labelEn')
    d.list = map(propertyKeys, (key) => String(key).toLowerCase())
  }
  
  // 将 filters转换成数组
  if(isString(d.filters) && fullname) {
    const target = d.filters.split('_')
    const tmp = []
    for(let i = 0, len = target.length; i < len; i += 2) {
      const label = fullname[target[i]] || target[i]
      tmp.push({ label, value: target[i + 1] })
    }
    d.filters = tmp
  }

  const isListing = !!d?.listing?.slug
  let url = ''

  if(isListing) {
    url = propertyDetail(d.listing.slug, locale)
  } else {
    const isMobile = isWindow ? document.documentElement.clientWidth < 768 : false
    const { isMapListing, param } = getListingModeParam(d, isMobile)

    // tag 需要传入对象
    if(/\d+/.test(param.tag)) {
      param.tag = { name: param.tagSlug || '', slug: param.tagSlug || '', value: param.tag }
    }
    
    url = urls.listing(type, param, locale, isMapListing)
  }
  
  return {
    ...pick(item, ['name', 'lastUpdate', 'listTime']),
    type: isListing ? 'listing' : type,
    multiple: true,
    track: item.track !== undefined ? item.track : true,
    param: item.params,
    id: `search_${item.id}`,
    data: d,
    url,
    fireEvent: !isListing
  }
}

export default function useSearchRecording(limit = 25) {
  const locale = useLocale()
  const ts = useTranslations('search')
  const ta = useTranslations('agent')
  const { pushTrackSearch } = useTracking()

  const { data, isLoading: isRequired, mutate } = useFetch('user-search-record', { limit })
  const recordItems = useMemo(() => (isArray(data) ? data : []), [data])

  const [records, setRecords] = useState(
    map(recordItems, (item) => getInitRecordOption(item, locale))
  )

  // 获取类型名称
  const getSubtypeName = useCallback(
    (d) => getSearchTypeName(d, ta, ts), 
    [ta, ts]
  )
  
  // 添加记录
  const pushRecord = useCallback(({ type, param }) => {
    if(param) {
      pushRecordApi(param, type).then((res) => {
        const other = res.oldId
          ? filter(recordItems, (f) => f.id !== res.oldId)
          : recordItems
        
        mutate([res, ...other], { revalidate: false })
      })
    }
  }, [mutate, recordItems])

  // 将记录排在头部
  const headRecord = useCallback((d = {}) => {
    const nativeId = String(d.id).startsWith('search') 
      ? parseInt(d.id.split('_')[1], 10)
      : d.id
    
    const target = filter(recordItems, (f) => f.id === nativeId)
    const other = filter(recordItems, (f) => f.id !== nativeId)
    
    // 添加事件统计
    if(target[0] && d?.fireEvent) {
      const { params, paramMeta } = target[0]
      const searchParam = {
        ...paramMeta,
        type: typeOfId[params.typeId],
        searchType: 'history_search',
        category: isArray(paramMeta?.ptnCategory) 
          ? map(paramMeta.ptnCategory, 'id')
          : [],
      }
      
      pushTrackSearch(searchParam, params)
    }
    
    // 将记录放在第一位
    fetchPut(`user-search-record/${nativeId}`).then(() => {
      mutate([...target, ...other], { revalidate: false })
    })
  }, [recordItems, mutate, pushTrackSearch])

  // 添加房源列表搜索记录
  const addSearchRecord = useCallback((d = {}) => {
    const { type, apiParam, searchParam } = d
    let param = apiParam || args.listingApi(type, searchParam)
    
    // 搜索的房源
    if(searchParam?.listing?.slug) {
      param = {
        typeId: param.typeId,
        listingUid: searchParam.listing.slug.split('/').reverse()[0] 
      }
    }
    
    pushRecord({ type, param })
  }, [pushRecord])

  useEffect(() => {
    setRecords(map(recordItems, (item) => getInitRecordOption(item, locale)))
  }, [recordItems, locale])
  
  return {
    records,
    isRequired,
    headRecord,
    getSubtypeName,
    pushRecord,
    addSearchRecord,
  }
}
