import { cookieGet, cookieSet } from '@lib/utils'
import { HG_LISTING_MODE } from '@lib/config'
import { omit, values } from 'lodash-es'
import { modeType } from './parse'
import { getCookieOption } from './get-cookie-option'

/**
 * 搜索跳转到列表，生成url前，设置mode参数
 * 默认跳转到 [单列模式]
 */
export function getListingModeParam(param, isMobile) {
  const name = cookieGet(HG_LISTING_MODE) || modeType.listing
  const tmp = omit({ ...param }, values(modeType))
  
  const isMapListing = isMobile || name === modeType.map || !!param.school || !!param.street
  
  return {
    isMapListing,
    param: isMapListing
      ? { ...tmp, [modeType.listing]: [] }
      : { ...tmp, [name]: [] }
  }
}

export function setListingMode(mode) {
  const option = getCookieOption()
  cookieSet(HG_LISTING_MODE, mode, option)
}
