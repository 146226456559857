/**
 * HoverDropdown
 * 
 * @Author: Focci
 * @Date: 2024-01-10 14:17:57
 * @Last Modified by: Focci
 * @Last Modified time: 2024-01-10 14:17:57
 */

'use client'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import { isWindow } from '@lib/utils'

export default function HoverDropdown({
  trigger,
  tag = 'div',
  tagProps,
  className = 'right-0',
  wrapperClassName = '',
  children,
  onEnter = () => {},
  hoverType = 'hover', // click, hover
}) {
  const TriggerTag = tag
  const [ready, setReady] = useState(false)

  const refEl = useRef()
  const isHover = hoverType === 'hover'

  const trigerProps = useMemo(() => {
    const handleEnter = () => {
      setReady((prev) => (isHover ? true : !prev))
      onEnter()
    }

    return { [isHover ? 'onMouseEnter' : 'onClick']: handleEnter }
  }, [onEnter, isHover])

  // 添加区域外点击事件
  useEffect(() => {
    const enable = isWindow && !isHover
    const handleClick = (e) => {
      const el = refEl.current
      if(el && !el.contains(e.target)) {
        setReady(false)
      }
    }

    enable && document.addEventListener('click', handleClick, false)
    return () => {
      enable && document.removeEventListener('click', handleClick, false)
    }
  }, [isHover])

  return (
    <div 
      className={clsx([isHover && 'group/hover-dropdown-wrapper', wrapperClassName])}
      ref={refEl}
    >
      <TriggerTag role="button" {...tagProps} {...trigerProps}>
        {trigger}
      </TriggerTag>
      <div
        className={clsx([
          'pt-1 absolute z-50',
          isHover
            ? 'hidden group-hover/hover-dropdown-wrapper:block group-hover/hover-dropdown-wrapper:animate-fade-in'
            : (!ready && 'hidden'),
          className
        ])}
      >
        {ready && children}
      </div>
    </div>
  )
}

HoverDropdown.propTypes = {
  trigger: PropTypes.node,
  tag: PropTypes.node,
  className: PropTypes.string,
  tagProps: PropTypes.object,
  children: PropTypes.node,
  hoverType: PropTypes.oneOf(['hover', 'click']),
}
