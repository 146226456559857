/**
 * HeadBarResposive
 * 
 * @Author: Focci
 * @Date: 2023-10-05 09:41:36
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-05 09:41:36
 */

'use client'

import PropTypes from 'prop-types'
import useLazyComponent from '@hook/useLazyComponent'
import SVGMenuSolid from '@img/menu-solid.svg'
import CloseIcon from '@mui/icons-material/Close'
import SVGLogo from '@img/logo.svg'
import SVGLogoEn from '@img/logo-en.svg'
import SVGLogoAU from '@img/logo-au.svg'
import useLogin from '@hook/useLogin'
import PersonIcon from '@mui/icons-material/Person'
import Link from 'next/link'
import { isAU } from '@lib/utils'
import { useLocale } from 'next-intl'
import { chineseCheck } from '@i18n/config'
import * as routeCommon from '@lib/route/common'
import { ButtonBase, Avatar, Drawer, CircularProgress } from '@comp/material'
import { useCallback, useState } from 'react'
import { LocaleSwitcher, LangSwitcher } from '../location-locale'

export default function HeadBarResposive({
  flag,
  logo,
  logoLink = '',
}) {
  const { openSigninDialog, isLogin, user } = useLogin()
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')
  const { Wedget: HeadBarResposiveBody, getWedget, ready } = useLazyComponent()

  const handleOpenMenu = useCallback(() => {
    setOpen(true)
    getWedget(() => import('./HeadBarResposiveBody'))
    setType('menu')
  }, [getWedget])

  const handleOpenProfile = useCallback(() => {
    if(!isLogin) {
      openSigninDialog()
    } else {
      setOpen(true)
      getWedget(() => import('./HeadBarResposiveBody'))
      setType('profile')
    }
  }, [isLogin, openSigninDialog, getWedget])

  const lang = useLocale()
  const websiteLogo = chineseCheck(lang) 
    ? isAU
      ? <SVGLogoAU width="6rem" height="1.5rem" /> 
      : <SVGLogo width="4rem" /> 
    : <SVGLogoEn width="6rem" />

  return (
    <>
      <div 
        className="
          h-[2.8rem] bg-white flex items-center justify-between p-2 
          border-b
        "
      >
        <div className="flex items-center gap-x-2">
          <ButtonBase onClick={handleOpenMenu} aria-label="Menu">
            <SVGMenuSolid className="text-primary" />
          </ButtonBase>
          <Link
            aria-label="Hougarden"
            href={logoLink || routeCommon.home(lang)}
            className="flex items-center shrink-0 text-primary"
          >
            {logo || websiteLogo}
          </Link>
          <LocaleSwitcher 
            flag={flag}
            triggerProps={{ className: '', showIcon: false }}
            className="left-4"
          />
        </div>
        <div className="flex items-center gap-x-2">
          <ButtonBase 
            className="rounded-full" 
            onClick={handleOpenProfile}
            aria-label="Profile"
          >
            <Avatar
              {...(isLogin ? { src: user?.avatar } : null)}
              sx={{ width: '1.825rem', height: '1.825rem' }}
            >
              <PersonIcon sx={{ fontSize: '1.3rem' }} />
            </Avatar>
          </ButtonBase>
          <LangSwitcher 
            triggerProps={{ className: 'flex-col text-f.6' }} 
          />
        </div>
      </div>
      <Drawer 
        anchor={type === 'profile' ? 'right' : 'left'} 
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="text-right p-3 border-b bg-white sticky top-0 z-20">
          <ButtonBase onClick={() => setOpen(false)}>
            <CloseIcon sx={{ fontSize: '1.5rem' }} />
          </ButtonBase>
        </div>
        <div className="w-72">
          <HeadBarResposiveBody 
            flag={flag} 
            type={type} 
            onClosePanel={() => setOpen(false)} 
          />
          {!ready && (
            <div className="text-center my-10">
              <CircularProgress />  
            </div>
          )}
        </div>
      </Drawer>
    </>
  )
}

HeadBarResposive.propTypes = {
  logo: PropTypes.node,
  logoLink: PropTypes.string,
}
