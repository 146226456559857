/**
 * FilterSearchIcon
 * 
 * @Author: Focci
 * @Date: 2024-09-30 10:37:16
 * @Last Modified by: Focci
 * @Last Modified time: 2024-09-30 10:37:16
 */

'use client'

import SearchIcon from '@mui/icons-material/Search'
import useApp from '@hook/useApp'
import useLazyComponent from '@hook/useLazyComponent'
import useTracking from '@hook/useTracking'
import useSearchRecording from '@hook/useSearchRecording'
import useProgressRouter from '@hook/useProgressRouter'
import { ButtonBase } from '@comp/material'
import { useLocale, useTranslations } from 'next-intl'
import { useCallback, useMemo, useState } from 'react'
import { FilterSkeletonCircular } from '@comp/page/common/filter/listing/FilterSkeleton'
import { getListingModeParam } from '@lib/listing-mode'
import { args, urls } from '@lib/parse'
import { houseSearchType } from '@lib/config'
import { isFunction } from 'lodash-es'

function FilterSearchDefaultTrigger() {
  const ts = useTranslations('search')

  return (
    <div className="flex items-center text-white">
      <SearchIcon />
      <span>{ts('search')}</span>
    </div>
  )
}

export default function FilterSearchIcon({
  Trigger = FilterSearchDefaultTrigger,
  triggerClassName,
  type = houseSearchType.property,
}) {
  const locale = useLocale()
  const router = useProgressRouter()
  const { isMobile } = useApp()
  const { pushTrackSearch } = useTracking()
  const { addSearchRecord } = useSearchRecording()
  const { Wedget, getWedget, ready } = useLazyComponent()
  const [open, setOpen] = useState(false)

  const initial = useMemo(() => ({
    type,
    searchParam: { list: [] },
    defaultSearchValue: [],
  }), [type])

  const handleClick = useCallback(() => {
    setOpen(true)
    getWedget(() => import('@page/common/filter/combine/filter-modal/index'))
  }, [getWedget])

  const toListing = useCallback((typ, searchParam, toMapListing) => {
    const url = urls.listing(typ, searchParam, locale, toMapListing)
    const apiParam = args.listingApi(typ, searchParam)
    // 添加访问记录
    addSearchRecord({ type: typ, searchParam, apiParam })
    
    // house_search事件
    pushTrackSearch({ ...searchParam, type: typ }, apiParam, toMapListing)
    router.routerPush(url)
  }, [addSearchRecord, pushTrackSearch, locale, router])

  const handleConfirm = useCallback(({ currentType, filterObject }) => {
    const { isMapListing } = getListingModeParam(initial, isMobile)
    toListing(currentType, filterObject, isMapListing)
  }, [initial, isMobile, toListing])

  return (
    <>
      <ButtonBase onClick={handleClick} className={triggerClassName}>
        {isFunction(Trigger) ? <Trigger /> : Trigger}
      </ButtonBase>
      {open && !ready && <FilterSkeletonCircular />}
      <Wedget
        open={open}
        initial={initial}
        onClose={() => setOpen(false)}
        onConfirm={handleConfirm}
      />
    </>
  )
}

// FilterSearchIcon.propTypes = {
//   className: PropTypes.string,
// }
