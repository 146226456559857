/**
 * useFetch
 * 
 * @Author: Focci
 * @Date: 2023-06-23 11:50:55
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-23 11:50:55
 */

import useSWR from 'swr'
import { clientFetchData } from '@lib/fetch/client'
import { isFunction } from 'lodash-es'
import { useLocale } from 'next-intl'
import { 
  useCallback, useEffect, useMemo, useRef, useState 
} from 'react'

/**
 * 
 * @param {String} url Url
 * @param {Object} param 请求参数
 * @param {Object} opts 其它请求参数
 * @param {Object} swrOptions swr options
 * @returns 
 */
export default function useFetch(url, param, opts) {
  const {
    method = 'get', options, token, uuid, swrOptions 
  } = opts ?? {}
  
  const controller = useRef()
  const [isRefetch, setIsRefetch] = useState(false)

  const lang = useLocale()
  const swrKey = useMemo(() => [param, url, lang], [param, url, lang])
  
  const cancel = useCallback(() => {
    if(isFunction(controller.current?.abort)) {
      controller.current.abort()
      controller.current = null
    }
  }, [controller])

  const fetcher = useCallback(([p, u = url]) => {
    cancel()
    controller.current = new AbortController()

    const { signal } = controller.current
    const option = { ...options, signal }

    return clientFetchData(method, u, p, option, token, uuid)
  }, [cancel, method, options, token, url, uuid])

  const {
    data, error, isLoading, isValidating, mutate
  } = useSWR(url ? swrKey : null, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    ...swrOptions
  })

  // 重新请求 p: param
  const refetch = useCallback(async (p, u = url) => {
    setIsRefetch(true)
    mutate(
      await fetcher([p, u]).finally(() => setIsRefetch(false)),
      { revalidate: false }
    )
  }, [fetcher, mutate, url])

  useEffect(() => () => cancel(), [cancel])

  return {
    data, 
    error,
    refetch,
    isLoading, 
    isValidating: isValidating || isRefetch,
    cancel,
    mutate,
  }
}
