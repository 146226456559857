import clsx from 'clsx'
import { CircularProgress } from '@comp/material'

export function FilterSkeleton() {
  return (
    <div className="flex items-center gap-2">
      <span className="px-8 h-8 rounded-full bg-cf0" />
      <span className="px-8 h-8 rounded-full bg-cf0" />
      <span className="px-8 h-8 rounded-full bg-cf0" />
    </div>
  )
}

export function FilterSkeletonInput({
  className = 'lg:w-144'
}) {
  return (
    <div 
      className={clsx(['min-h-[2.6rem] w-full rounded-md bg-cf8', className])} 
    />
  )
}

export function FilterBarSkeleton({
  className
}) {
  return (
    <div className="flex flex-col md:flex-row items-start md:items-center gap-x-4 gap-y-2 w-full">
      <FilterSkeletonInput className={className} />
      <FilterSkeleton />
    </div>
  )
}

export function FilterSkeletonCircular() {
  return (
    <div className="fixed inset-0 bg-white/60 z-[1000] flex items-center justify-center">
      <CircularProgress size="1.5rem" />
    </div>
  )
}
